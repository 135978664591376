* {
    font-family: 'Gilroy';
}

a {
    text-decoration: underline;
    color: inherit;
    font-weight: bolder;
}

a:hover {
    text-decoration: none;
    color: inherit;
}

select {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(../images/triangle-green-down.svg);
    background-repeat: no-repeat;
    background-position: right 10px center;
    padding-right: 28px;
    padding-left: 8px;
}
