@media (max-width: 1199.98px) {
	.userpanel__header {
		align-items: flex-start;
	}
}

@media (max-width: 991.98px) {
	.ellipsesbg {
		min-height: 620px;
	}
	
	.header_logo_main {
		font-size: 32px;
	}
    
    .paging__page_links {
        justify-content: flex-end;
    }
    
    .control-panel.user-list .control-panel__header .form_row {
        flex-wrap: wrap;
		margin-left: -8px
    }
	
	.control-panel .control-panel__header .form__row_item.search-input {
		width: 100%;
	}
	
	.control-panel.user-list .control-panel__header .form__row_item {
		margin-left: 8px;
		margin-right: 0;
		margin-bottom: 6px;
		flex-grow: 1;
	}
	
	
}

@media (max-width: 767.98px) {
	.side-padding-main {
    	padding-left: 25px;
    	padding-right: 25px;
	}
	
	.header {
    	height: 64px;
	}
	
	.button {
    	display: inline-block;
    	font-weight: 500;
    	font-size: 16px;
    	line-height: 36px;
    	height: 36px;
    	padding: 0 8px;
    	min-width: 106px;
	}
	
	.h.h_large {
    	font-size: 48px;
	}
	
	.list-item-checked {
		font-size: 16px;
		min-height: 24px;
    	line-height: 24px;
		padding-left: 36px;
	}
	
	.list-item-checked::before {
		width: 24px;
    	height: 24px;
	}
	
	.button.button_large {
    	line-height: 48px;
    	height: 48px;
	}
	
	.ellipsesbg {
		min-height: 480px;
	}
	
	.header_logo_main {
		font-size: 24px;
	}
    
    .paging__pagelink,
    .pagelink_dots,
    .paging__stat {
        display: none;
    }
    
    .paging__nextlink {
        margin-left: 16px;
    }
    
    .paging__prevlink {
        margin-right: 16px;
    }
	
	.form__row_input,
	.form__row_select {
		height: 36px;
	}
	
	.userpanel__header_info {
		flex-wrap: wrap;
	}
	
	.userpanel__header_info_left {
		margin-bottom: 20px;
	}
	
	.userpanel__header {
		padding: 20px;
	}
	
	.control-panel__row {
		padding: 20px;
	}
    
    .control-panel__header_filter-button::before,
    .icon-download::before {
        margin-right: 0;
    }
    
    .control-panel__header .form__row_item {
        margin-right: 4px;
        margin-left: 4px;
    }
    
    .control-panel__header_filter-wrap {
        position: static;
    }
    
    .control-panel__header {
        position: relative;
    }
    
    .control-panel__header_filter-body {
        left: 0;
        top: 70px;
        width: auto;
    }
    
    .panel-counters__counters-area {
        margin-top: 12px;
        justify-content: space-between;
/*        flex-wrap: wrap;*/
    }
}

@media (max-width: 575.98px) {
	
	.side-padding-main {
    	padding-left: 17px;
    	padding-right: 17px;
	}
	
	.footer {
		height: 55px;
	}
	
	.footer_logo_main {
		font-size: 24px;
	}
	
	.ellipsesbg__content {
		padding: 24px 34px 32px 37px;
	}
	
	.ellipses__content_header {
		font-size: 28px;
	}
	
	
	.paging__settings,
	.pagination__select {
		font-size: 14px;
	}
	
	.userpanel__avatar {
		flex: 0 0 70px;
    	height: 70px;
		width: 70px;
	}
	
	.userpanel__username {
		font-size: 18px;
		line-height: 21px;
	}
	
	.userpanel__role-n-status,
	.userpanel__lastvisit,
	.userpanel__link_to_admin {
		font-size: 14px;
	}
    
    .vk-infograph-charts {
        margin-left: 16px;
        margin-right: 16px;
    }
}

@media (max-width: 481px) {
	.button-check {
		margin-bottom: 10px;
	}
}