.body {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    font-family: 'Gilroy';
    font-size: 14px;
    display: flex;
    flex-direction: column;
}

.header {
    height: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.page {
    background: rgb(255,255,255);
    background: linear-gradient(139deg, rgba(255,255,255,1) 0%, rgba(193,232,230,0.9192051820728291) 50%, rgba(255,255,255,1) 100%);
    flex-grow: 1;
    overflow-x: hidden;
    position: relative;
    border-top: 1px solid #ECEBEB;
    border-bottom: 1px solid #ECEBEB;
}

.page.page_white {
    background: #FAFEFF;
}

.header_logo_main {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    color: #036B84;
    text-decoration: none;
}

.header_logo_main:hover {
    color: #036B84;
    text-decoration: none
}

.side-padding-main {
    padding-left: 52px;
    padding-right: 52px;
}

.button {
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    line-height: 42px;
    height: 42px;
    padding: 0 12px;
    box-sizing: border-box;
    text-align: center;
    text-align: center;
    text-decoration: none;
    min-width: 120px;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
}

.button.button_large {
    line-height: 54px;
    height: 54px;
    padding: 0 30px;
}

.button.button_darkgreen {
    background: linear-gradient(82.61deg, #036B84 -4.43%, #0F748C 120.15%);
    color: #fff;
    border: none;
    font-weight: normal;
}

.button.button_darkgreen:hover {
    color: #fff;
    background: linear-gradient(82.61deg, #238Ba4 -4.43%, #2F84aC 120.15%);
    text-decoration: none;
}

.button.button_green {
    background-color: #0D8B8B;
    color: #fff;
    border: none;
    font-weight: 600;
    font-size: 16px;
}

.button.button_green:hover {
    background-color: #3DaBaB;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
}

.button_fwidth {
    display: block;    
    width: 100%;
}

.button.button_transparent {
    background: transparent;
    color: #3C4552;
}

.button.button_transparent:hover {
    color: #5C6572;
}

footer {
    background: #0B7189;
}

.h {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 120%;
}

.h.h_large {
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 120%;
}

.list {
    margin: 0;
    padding: 0;
}



.list-item-checked {
    display: block;
    list-style: none;
    min-height: 30px;
    line-height: 30px;
    margin-bottom: 25px;
    padding-left: 55px;
    position: relative;
    font-style: normal;
font-weight: normal;
font-size: 18px;
}

.list-item-checked::before {
    position: absolute;
    display: block;
    content: '';
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #036B84;
    background-image: url(../images/check-white.svg);
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;
    left: 0;
    top: 0;
}

.singup__inner_top {
    display: flex;
    margin-bottom: 20px;
}

.signup__inner_bottom {

}

.signup__forgot_link {
    display: inline-block;
    font-weight: bold;
    font-size: 21px;
    color: #363650;
    text-decoration: none;
}

.signup__restore_link {
    display: inline-block;
    font-size: 18px;
    text-decoration-line: underline;
    color: #878794;
}

.ellipses {
/*
    height: 734px;
    width: 932px;
    border: 2px solid rgba(12,113,138, 0.25);
    border-radius: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
*/
}

.ellipsesbg__image {
    position: relative;
    right: -20px;
    max-width: 100%;
	filter: drop-shadow(0px 40px 50px rgba(175, 195, 200, 0.25));
	border-radius: 10px;
}

.ellipses__inner_ellipse {
    position: absolute;
    border-radius: 50%;
}

.ellipses__inner_ellipse.ellipses_1 {
    width: 86%;
    height: 86%;
    right: 5%;
    top: 7%;
    border: 2px solid rgba(12,113,138, 0.35);
}

.ellipses__inner_ellipse.ellipses_2 {
    width: 70%;
    height: 70%;
    right: 10%;
    top: 15%;
    border: 2px solid rgba(12,113,138, 0.5);
}

.footer_logo_main {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    color: #fff;
    text-decoration: none;
}

.footer_logo_main:hover {
    text-decoration: none;
    color: #fff;
}

.sidepanel {
    position: absolute;
    width: 100%;
    max-width: 560px;
    top: 0;
    bottom: 0;
    left: -560px;
    background: #fff;
    border-right: 1px solid #ECEBEB;
    z-index: 100;
    transition: left ease 0.5s;
}

.sidepanel.sidepanel_active {
    left: 0;
    transition: left ease 0.5s;
}

.sidepanel__items {
    padding: 100px 80px 50px 50px;
}

.sidepanel_header {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 120%;
    color: #363650;
    margin-bottom: 32px;
}

.form__block_label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #69686D;
    margin-bottom: 4px;
}

.form__block_label.light-style {
    font-weight: 500;
}

.form__block_input {
    display: block;
    width: 100%;
    border-width: 0 0 2px 0;
    border-style: solid;
    border-color: #A6ADB5;
    font-size: 16px;
    padding: 8px 0;
    outline: none;
}

.form__block_img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.form__block_input.light-style {
    background-color: #FFFFFF;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 5px;
    padding-left: 6px;
    padding-right: 6px;
}

.form__block_select {
    display: block;
    width: 100%;
    border: 1px solid #A6ADB5;
    font-size: 16px;
    outline: none;
    border-radius: 5px;
}

.form__block_select.light-style {
    border: 1px solid #EBEBEB;
}

.form__block_input::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #9EA3AB;
}





.button.button_white {
    background: #fff;
    border: 2px solid #036B84;
    line-height: 38px;
    color: #036B84;
    border-radius: 1px;
}

.button.button_white:hover {
    border-color: #0D5C6E;
    color: #0D5C6E;
    text-decoration: none;
}

.button.button_white:active {
    border-color: #198DA8;
    color: #198DA8;
    text-decoration: none;
}

.form__forgot_link {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    text-decoration-line: underline;
    color: #036B84;
}

.form__forgot_link:hover {
    color: #238ba4;
}

.form__text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #9EA3AB;
}

.link {
    text-decoration: none;
    color: #036B84;
    font-weight: 600;
}

.link:hover {
    text-decoration: none;
    color: #238Ba4;
}

.link:active {
    text-decoration: none;
    color: #43aBc4;
}

.link.link_back {
    position: relative;
    display: inline-block;
    padding-left: 23px;
    background: url(../images/angle-left.svg) left center no-repeat;
}

.message {
    display: block;
    padding: 24px;
    font-size: 16px;
    line-height: 140%;
    border-radius: 7px;
    margin: 10px 0;
}

.message_success {
    background: #E7FEEB;
    border: 2px solid #62BE70;
    color: #4BAA5A;
}

.message_fwidth {
    width: 100%;
}

.ellipses_center {
    margin-left: auto;
    margin-right: auto;
}

.ellipsesbg__content {
    background: #fff;
    width: 100%;
    max-width: 400px;
    margin:  40px auto 100px;
    padding: 30px 50px 50px;
    border-radius: 5px;
    position: relative;
    z-index: 10;
}

.ellipses__content_header {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 120%;
    color: #363650;
}

.form__block_dragndrop {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.form__block_dragndrop_outer {
    border: 2px dashed #B9D6DD;
    box-sizing: border-box;
    border-radius: 7px;
    padding-top: 34px;
    padding-bottom: 8px;
    background: url(../images/folder.svg) center 9px no-repeat, #F4F9FA;
    color: #69686D;
    text-align: center;
    width: 100%;
    margin: 8px 0;
    position: relative;
}

.ellipsesbg {
    height: calc(100% - 20px);
    min-height: 734px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    background: url(../images/ellipses.svg) left center no-repeat;
    background-size: auto 100%;
    position: relative;
    right: -52px;
}

.flex-horizontal-right {
    display: flex;
    justify-content: flex-end;
}

.flex-horizontal-left {
    display: flex;
    justify-content: flex-start;
}

.flex-horizontal-center {
    display: flex;
    justify-content: center;
}

.flex-vertical-right {
    display: flex;
    align-items: flex-end;
}

.flex-vertical-left {
    display: flex;
    align-items: flex-start;
}

.flex-vertical-center {
    display: flex;
    align-items: center;
}

.ellipsesbg_center {
    background-size: 100%;
    right: auto;
}

.form__checkbox_wrap {
    display: flex;
    align-items: flex-start;
}

.form__checkbox_input {
    display: none;
}

.form__checkbox_input:checked+.form__checkbox_input_mark {
    background: url(../images/check-green.svg) center center no-repeat;
    background-size: 70% 70%;
    transition: all ease 0.1s;
}

.form__checkbox_input_mark {
    display: block;
    flex-grow: 0;
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-right: 8px;
    margin-top: 1px;
    border: 2px solid #0B7189;
    box-sizing: border-box;
    border-radius: 3px;
    background: url(../images/check-green.svg) center center no-repeat;
    background-size: 0 0;
    cursor: pointer;
    transition: all ease 0.1s;
}

.form__checkbox_input.grey-checkbox+.form__checkbox_input_mark {
    border: 1px solid #CACACA;
}

.form__checkbox_input.no-margin+.form__checkbox_input_mark {
    margin: 0;
}

.form_row {
    display: flex;
/*	flex-wrap: wrap;*/
}

.control-panel__header {
    background: #ECF6F9;
    padding: 19px 30px;
    border: 1px solid #ECEBEB;
    border-width: 1px 1px 0;
    border-radius: 15px 15px 0 0;
}

.tabbed-panel .control-panel__header {
    border-radius: 0 15px 0 0;
}

.tabbed-panel__content_footer {
	padding: 12px;
}

.form__row_item {
    flex-grow: 1;
}

.control-panel__header .form__row_item {
    margin-right: 18px;
    margin-left: 18px;
}

.control-panel__header .form__row_item:first-child {
    margin-left: 0;
}

.control-panel__header .form__row_item:last-child {
    margin-right: 0;
}

.flexgrow-1 {
    flex-grow: 1;
}

.flexgrow-2 {
    flex-grow: 2;
}

.flexgrow-3 {
    flex-grow: 3;
}

.tbl__header_cell {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    color: #3C4552;
    border-top: none;
    height: 56px;
    vertical-align: middle;
    border-bottom: 1px solid #E4E4E4;
}

.control_panel__body {
    background: #fff;
    border: 1px solid #ECEBEB;
    border-radius: 15px 15px 15px 15px;
}

.control-panel__header+.control_panel__body {
    border-radius: 0 0 15px 15px;
}

.text_success {
    color: #09AD37;
    font-weight: 500;
}

.text_danger {
    color: #AD1309;
    font-weight: 500;
}

.tbl {
    width: 100%;
    border-collapse: collapse;
}

.tbl__cell {
    height: 56px;
    vertical-align: middle;
    border-top: 1px solid #E4E4E4;
}

.tbl__row-hidden .tbl__cell {
    border-top: none;
}

.tbl__row:first-child .tbl__cell {
    border-top: none;
}

.tbl__cell.empty-cell::before {
    display: block;
    content: '';
    height: 2px;
    max-width: 67px;
    background: #036B84;
    opacity: 0.6;
}

.tbl__header_cell,
.tbl__cell {
    padding-left: 16px;
}

.tbl__header_cell:last-child,
.tbl__cell:last-child {
    padding-right: 16px;
}

.user-list__avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
}

.tbl__header_cell.checkbox-column {
    width: 18px;
}

.avatar-column {
    width: 36px;
}

.tbl__showmore {
    width: 18px;
    height: 18px;
    position: relative;
    border-radius: 50%;
    background: url(../images/triangle-white-down.svg) center center no-repeat;
    background-color: #0D8B8B;
    transform: rotate(0deg);
    transition: all ease 0.3s;
    cursor: pointer;
}

.showmore-mob .tbl__showmore {
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    color: #fff;
    background-image: none;
}

.showmore-mob .tbl__showmore::before {
    display: inline;
    content: '+';
}

.showmore-mob .tbl__showmore.active::before {
    content: '-';
}

.tbl__showmore.active {
    transform: rotate(180deg);
    transition: all ease 0.3s;
}

.showmore-column {
    width: 18px;
}

.showmore-column.showmore-mob,
.tbl__cell.showmore-mob {
    display: none;
}

.tbl__row:hover {
    background: #ECF6F9;
}

.tbl__row.active {
    background-color: #ECF6F9;
}

.tbl__row.active+.tbl__row-hidden,
.tbl__row.active+.tbl__row-mob+.tbl__row-hidden,
.tbl__row.active+.tbl__row-mob {
    display: table-row;
}

.tbl__row-mob {
    display: none;
}



.tbl__row.active .tbl__cell {
/*    border-top: none;*/
}

.form__row_input,
.form__row_select {
    background-color: #FFFFFF;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 5px;
    height: 42px;
    padding-left: 8px;
    padding-right: 8px;
}

.control-panel__row .form__row_input,
.control-panel__row .form__row_select {
	width: 100%;
}

.control-panel__header .form__row_input,
.control-panel__header .form__row_select{
    font-size: 16px;
    background-color: #FFFFFF;
    border: 1px solid #CACACA;
    box-sizing: border-box;
    border-radius: 5px;
    padding-left: 26px;
    padding-right: 26px;
}

.form__row_input.search-input {
    padding-left: 36px;
    background-image: url('../images/loupe.svg');
    background-position: 9px center;
    background-repeat: no-repeat;
}

.paging__pagelink {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 28px;
    height: 28px;
    font-size: 18px;
    line-height: 21px;
    border-radius: 3px;
    margin: 0 3px;
    text-decoration: none;
    font-weight: 400;
}

.paging__pagelink:not(.active):hover {
    border-bottom: 1px solid #036B84;
    border-top: 1px solid transparent;
    border-radius: 0;    
}

.paging__pagelink.active {
    background: #036B84;
    color: #fff;
}

.paging__page_links {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.paging__prevlink {
    margin-right: 32px;
    height: 20px;
}

.paging__nextlink {
    margin-left: 32px;
    height: 20px;
}

.paging {
    margin-top: 50px;
    margin-bottom: 50px;
}

.pagelink_dots {
    display: block;
    width: 28px;
    text-align: center;
    margin: 0 3px;
    font-size: 18px;
    cursor: default;
}

.paging__settings {
    font-size: 18px;
    display: inline-block;
}

.pagination__select {
    font-size: 16px;
    background-color: #FFFFFF;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 3px;
}

.paging__stat {
    display: inline-block;
    margin-right: 50px;
    font-size: 18px;
}

.user-list__lastvisit {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #0D1623;
}

.userpanel__lastvisit {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #0D1623;
}

.tbl__row-hidden {
    display: none;
    background-color: #ECF6F9;
}

.user-list__fio_cell {
    color: #0D8B8B;
    font-weight: 500;
}



.editable-value__select:disabled {
    color: inherit;
    background: none;
    opacity: 1;
}

.editable-value__select.color-green:disabled,
.editable-value__select.color-green {
    color: #09AD37;
    font-weight: 500;
}

.editable-value__select.color-red:disabled,
.editable-value__select.color-red {
    color: #AD1309;
    font-weight: 500;
}

.editable-value__select option {
    color: #0D1623;
}

.editable-value__select {
    border: none;
    outline: none;
    padding: 0;
    background-color:transparent;
    padding-right: 24px;
}

.control-panel {
    color: #0D1623;
}

.editable-value__edit-icon {
    display: block;
    min-width: 16px;
    height: 16px;
    background: url(../images/icon__edit-green.svg) center center no-repeat;
    background-size: contain;
    margin-left: 4px;
}

.control-panel .tbl__row .editable-value__edit-icon {
    height: 0;
}

.control-panel .tbl__row.active .editable-value__edit-icon {
    height: 16px;
}

.user-list__editable-value {
    display: flex;
    align-items: center;
}

.save-cell {
    text-align: right;
}

.user-list__user_save_button {
    display: none;
}

.breadcrumbs {
    display: flex;
	flex-wrap: wrap;
    margin: 40px 0 30px;
}

.breadcrumbs__link {
    display: block;
    margin-right: 20px;
    padding-right: 26px;
    background: url(../images/angle-right.svg) right center no-repeat;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #7D7D7D;
    text-decoration: none;
}

.breadcrumbs__curlink {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #444444;
}

.userpanel__header {
    padding: 28px 57px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.userpanel__avatar {
    flex: 0 0 110px;
    height: 110px;
    width: 110px;
    border-radius: 50%;
    background-position: center center;
	margin-right: 20px;
    object-fit: cover;
}

.userpanel__username {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #444444;
}

.userpanel__role-n-status {
    font-size: 16px;
    line-height: 19px;
    color: #444444;
}

.userpanel__link_to_admin {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-decoration: underline;
    color: #0B718A;
}

.control-panel__row {
    padding: 15px 78px;
}

.control-panel__row .form_row .form__row_item {
    margin-right: 30px;
}

.control-panel__row .form_row .form__row_item:last-child {
    margin-right: 0;
}

.control-panel__row:nth-child(2n+1) {
    background-color: #fbfbfb;
}

.control-panel__row_header {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #444444;
}

.form__row_item_label {
    display: block;
	margin-bottom: 0px;
    margin-top: 8px;
}

.form_row-nogrow .form__row_item {
    flex-grow: 0;
    margin-right: 8px;
}

.userpanel {
    margin-bottom: 50px;
}

.tabbed-panel__content.active {
    display: block;
}

.tabbed-panel__tab {
    display: block;
    background: rgba(0, 0, 0, 0.04);
    border: 1px solid #ECEBEB;
    border-width: 1px 1px 1px 0;
    padding: 14px 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: rgba(3,107,132,0.7);
    cursor: default;
    text-decoration: none;
}

.tabbed-panel__tab:not(.active){
    cursor: pointer;
}

.tabbed-panel__tab.active {
    background-color: #fff;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #0D8B8B;
}

.tabbed-panel__tab:first-child {
    border-radius: 15px 0 0 0;
    border-width: 1px;
}

.tabbed-panel__tab:last-child {
    border-radius: 0 15px 0 0;
}

.tabbed-panel__tabs {
    display: flex;
    margin: 0;
    padding: 0;
}

.lineclamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    word-break: break-all;
    min-width: 100px;
}

.lineclamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.lineclamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.description-column {
    max-width: 300px;
}

.vk-community-tbl__description {
    max-width: 300px;
}

.control-panel__link {
    color: #0D8B8B;
    text-decoration: none;
    font-weight: normal;
}

.bolder {
    font-weight: bolder;
}

.icon-download::before {
    display: inline-block;
    vertical-align: text-bottom;
    content: '';
    width: 20px;
    height: 20px;
    background: url(../images/icon__download-white.svg) center center no-repeat;
    background-size: contain;
    margin-right: 8px;
}

.control-panel__header_filter-button {
    background: #fff;
    width: 100%;
    color: #036B84;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #CACACA;
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
}

.control-panel__header_filter-button:hover {
    color: #036B84;
    background: #f8f8f8;
}

.control-panel__header_filter-button:active {
    background: #fff;
}

.control-panel__header_filter-button::before {
    display: inline-block;
    vertical-align: middle;
    content: '';
    width: 19px;
    height: 15px;
    background: url(../images/icon__filter-green.svg) center center no-repeat;
    background-size: contain;
    margin-right: 8px;
}

.control-panel__header_filter-body {
    position: absolute;
    display: block;
    width: 320px;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    box-sizing: border-box;
    box-shadow: 0px 15px 25px rgba(153, 163, 186, 0.12);
    border-radius: 7px;
    top: calc(100% + 9px);
    right: 0;
    z-index: 100;
    padding: 28px 30px;
    text-align: left;
}

.control-panel__header_filter-body .form__block_select,
.control-panel__header_filter-body .form__block_input {
    height: 38px;
}

.control-panel__header_filter-wrap {
    position: relative;
}

.grow-0 {
    flex-grow: 0;
}

.chart {
    width: 100%;
    /* height: 440px!important; */
}

.vk-infograph-charts {
    margin-left: 30px;
    margin-right: 30px;
}

.fwidth {
	width: 100%;
}

.header__menu-btn {
	display: inline-block;
	width: 17px;
	height: 10px;
	margin-right: 10px;
	background: url(../images/icon__menu.svg) center center no-repeat;
	background-size: contain;
}

.header__left {
	display: flex;
	align-items: center;
}

.tbl__row-mob {
	background: #ECF6F9;
}

.tbl__cell-mob {
	display: flex;
    align-items: flex-start;
    justify-content: center;
	flex-wrap: wrap;
	background: #fff;
    margin: 8px 16px;
    padding: 4px;
    border-radius: 5px;
}

.tbl__cell-mob .cell-mob__outer {
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 8px;
	flex-basis: 150px;
}

.tbl__row-hidden_content {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
	padding-bottom: 8px;
}

.cell-mob__header {
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	color: #84878C;
}

.cell-mob__content {
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	color: #54585F;
}

.cell-mob__content .user-list__editable-value {
	justify-content: center;
} 

.panel-counters__counter-name {
    font-size: 14px;
    line-height: 16px;
    color: #0D1623;
}

.panel-counters__date {
    font-size: 18px;
    line-height: 21px;
    color: #626A74;
}

.panel-counters__name {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #036B84;
}

.panel-counters__counters-area {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.panel-counters__counter-number {
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    color: #0D1623;
}

.panel-counters {
    margin: 12px 18px;
}



.dotted-text {
    position: relative;
    padding-left: 16px;
}

.dotted-text::before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: calc(50% - 5px);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #000;
    vertical-align: middle;
}

.dotted-text.purple::before {
    background-color: #766EF4;
}

.dotted-text.red::before {
    background-color: #FF3F15;
}

.dotted-text.blue::before {
    background-color: #44D2FF;
}

.dotted-text.green::before {
    background-color: #0D8B8B;
}

.thumb-column {
    width: 45px;
}

.table__thumb {
    width: 45px;
    height: 45px;
}

.textclip {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px;
    text-overflow: ellipsis;
    max-width: 300px;
}

.table__thumb-wide {
    width: 86px;
    height: 48px;
}

.table__thumb-69x46 {
    width: 69px;
    height: 46px;
}

.userpanel__header_info {
	display: flex;
	justify-content: space-between;
}

.userpanel__header_info_left {
	margin-right: 20px;
}

.tabbed-panel__mob_tabs {
	background-color: #fff;
    border: 1px solid #ECEBEB;
    border-radius: 15px 0 0 0;
    padding: 14px 30px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #0D8B8B;
    line-height: 24px;
    color: rgba(3,107,132,0.7);
    cursor: default;
	outline: none;
}

.button.button_icon {
    min-width: 32px;
    flex: 0 0 32px;
    white-space: nowrap;
}

.panel-counters__counter {
    flex: 0 0 150px;
    margin-bottom: 10px;
}

.leftmenu__separator {
    height: 2px;
    background: #DDE0E1;
    margin: 4px 0;
}

.leftmenu__item_icon {
    flex: 0 0 36px;
    border-radius: 50%;
    height: 36px;
    background-color: rgba(3,107,132,0.15);
    margin-right: 16px;
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
}

.leftmenu__item {
    height: 52px;
    border-radius: 26px;
    padding: 8px;
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 6px;
    margin-top: 6px;
}

.leftmenu__item:hover {
    background-color: rgba(3,107,132,0.1)
}

.leftmenu__item_name {
    font-weight: normal;
font-size: 18px;
line-height: 21px;
font-weight: 500;
color: #414142;
}

.leftmenu__profile_photo {
    flex: 0 0 46px;
    height: 46px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 20px;
}

.leftmenu__profile {
    display: flex;
    align-items: center;
    padding-left: 8px;
    margin: 30px 0;
}

.leftmenu__yourname {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #036B84;
}

.leftmenu__yourrole {
    font-size: 16px;
    line-height: 19px;
    color: #414142;
    font-weight: 500;
}

.imagetile__cover {
	width: 100%;
	height: 235px;
	background-color: #f0f0f0;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 5px 5px 0 0;
}

.imagetile {
	height: calc(100% - 20px);
	background-color: #fff;
	border: 1px solid #DBDBDB;
	border-radius: 5px;
	margin-bottom: 20px;
}

.imagetile-wrap {
	margin-top: 48px;
}

.imagetile__descr_name {
	font-weight: 500;
	font-size: 12px;
	line-height: 120%;
	color: #90939C;
}

.imagetile__descr_text {
	font-weight: 500;
	font-size: 14px;
	line-height: 120%;
	color: #656974;
}

.imagetile__body {
	padding: 10px 14px 16px;
	box-sizing: border-box;
	border-radius: 5px;
}

.imagetile__tags {
	margin-top: 11px;
}

.imagetile__tag {
	display: inline-block;
	padding: 4px 15px;
	color: #fff;
	background: #036B84;
	text-decoration: none;
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 4px;
}

.imagetile__tag:hover {
	color: #fff;
	background: #0D8B8B;
}
